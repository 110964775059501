import { render, staticRenderFns } from "./OfferCardDetails.vue?vue&type=template&id=2215bd48&scoped=true"
import script from "./OfferCardDetails.vue?vue&type=script&lang=ts"
export * from "./OfferCardDetails.vue?vue&type=script&lang=ts"
import style0 from "./OfferCardDetails.vue?vue&type=style&index=0&id=2215bd48&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@4.3.0_lodash@4.17.21_prettier@2.8.8_react-dom@18.3.1_react@17.0_is2rcx2o52qv5puaxmhcmj5ubq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2215bd48",
  null
  
)

export default component.exports